<template>
  <div style="padding-bottom: 80px">
    <form-wizard
      ref="refFormWizard"
      color="#F55559"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Voltar"
      hide-buttons
      class="checkout-form-wizard steps-transparent"
      transition="fade"
    >
      <tab-content
        title="Escolha o arquivo"
        icon="feather icon-file"
      >
        <validation-observer
          ref="validatorArquivo"
          #default="{ handleSubmit }"
        >
          <b-form
            @submit.prevent="handleSubmit(formWizardNextStep)"
            @reset.prevent="resetForm"
          >
            <arquivo-importacao-step 
              :get-validation-state="getValidationState"
              :data.sync="importacao"
            />
          </b-form>
        </validation-observer>
      </tab-content>
      <tab-content
        title="Confirmar Importação"
        icon="feather icon-grid"
      >
        <validation-observer
          ref="validatorCampos"
          #default="{ validateWithInfo, handleSubmit }"
        >
          <b-form
            @submit.prevent="validaForm(validateWithInfo), handleSubmit(gravaImportacao)"
            @reset.prevent="resetForm"
          >
            <dados-importacao-step
              :get-validation-state="getValidationState"
              :importacao.sync="importacao"
              :go-back="goBack"
            />
          </b-form>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Vue from 'vue'
import swal from 'sweetalert2/dist/sweetalert2.min'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { $themeConfig } from '@themeConfig'
import ArquivoImportacaoStep from './steps/arquivo'
import DadosImportacaoStep from './steps/dados'

export default {
  name: 'ArquivoImportacao',
  components: {
    FormWizard,
    TabContent,
    ArquivoImportacaoStep,
    DadosImportacaoStep,
  },
  beforeMount() {
    const user = JSON.parse(localStorage.getItem('userName'))
    const arquivoRequest = {
      layoutId: this.$route.params.layoutId || null,
      clienteId: user.clienteId,
      clienteDepartamentoId: null,
      servicoId: null,
      nomeUsuarioGeracao: user.nome,
      reversa: false,
      redirecionado: this.$route.params.redirecionado || false,
    }
    this.$store.dispatch('arquivoImportacao/setArquivoRequest', arquivoRequest)
  },
  setup() {
    const appLoading = $themeConfig.app.appLoadingImage

    const arquivoStore = createNamespacedHelpers('arquivoImportacao')

    const user = JSON.parse(localStorage.getItem('userName'))

    const state = reactive({
      importacao: {
        files: [],
        arquivoRequest: arquivoStore.useGetters(['arquivoRequest']).arquivoRequest,
        items: [],
        loading: false,
      },
      dadosArquivo: {},
    })
    
    const refFormWizard = ref(null)

    const validatorArquivo = ref(null)
    const validatorCampos = ref(null)

    const resetFields = () => {
      state.importacao = ({
        files: [],
        arquivoRequest: {
          layoutId: null,
          clienteId: user.clienteId,
          clienteDepartamentoId: null,
          servicoId: null,
          nomeUsuarioGeracao: user.nome,
          reversa: false,
          redirecionado: false,
        },
        items: [],
        loading: false,
      })
      state.dadosArquivo = {}
      validatorArquivo.value.reset()
      validatorCampos.value.reset()
    }

    const formWizardReset = () => {
      resetFields()
      refFormWizard.value.reset()
    }
    
    const formWizardNextStep = () => {
      state.importacao.items = []
      appLoading.show()
      const { layoutId } = state.importacao.arquivoRequest
      const formData = new FormData()
      state.importacao.files.forEach(file => {
        formData.append('files', file)
      })
      formData.append('arquivoImportacao', JSON.stringify(state.importacao.arquivoRequest))
      store.dispatch('arquivoImportacao/montaGridDadosArquivo', formData)
        .then(dados => {
          appLoading.hide()
          if (dados.success && dados.body.arquivoValido) {
            state.dadosArquivo = dados.body
            store.dispatch('clienteLayoutParametro/findAllByLayoutId', layoutId)
            refFormWizard.value.nextTab()
          } else {
            Vue.$toast(
              {
                component: ToastificationContent,
                props: {
                  title:
                    `${dados.body.mensagemErro ? dados.body.mensagemErro : dados.body}`,
                  icon: 'AlertCircleIcon',
                  variant: 'primary',
                },
              },
              {
                timeout: 10000,
                draggable: true,
                hideProgressBar: false,
              },
            )
          }
        })
    }

    watch(() => state.importacao.arquivoRequest, value => {
      if (value.redirecionado) {
        if (value.servicoId) {
          formWizardNextStep()
        } else {
          Vue.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Não existe serviço cadastrado para etiquetas geradas via xml enviado por e-mail',
                icon: 'AlertCircleIcon',
                variant: 'primary',
              },
            },
            {
              timeout: 10000,
              draggable: true,
              hideProgressBar: false,
            },
          )
        }
      }
    })

    const goBack = () => {
      refFormWizard.value.prevTab()
    }
    
    const { refFormObserver, getValidationState, resetForm } = formValidation()

    const enviaRequisicoes = async items => {
      // const { layoutId } = state.importacao.arquivoRequest
      const erros = []
      let etiquetasGeradas = 0
      let etiquetasNaoGeradas = 0
      const qtdItems = items.length
      const itemsComErro = []
      // eslint-disable-next-line no-restricted-syntax
      for (const item of items) {
        if (item && !item[0].gerado) {
          item[0].loading = true
          if (item[0].etiqueta.movimentacaoConteudo) {
            item[0].etiqueta.conteudo.push(item[0].etiqueta.movimentacaoConteudo)
          }
          if (item[0].etiqueta.movimentacaoVolume) {
            item[0].etiqueta.volumes.push(item[0].etiqueta.movimentacaoVolume)
          }
          const local = 'etiqueta/save'
          // eslint-disable-next-line no-await-in-loop, no-loop-func
          await store.dispatch(local, item[0].etiqueta).then(response => {
            if (response && response.status === 201) {
              item[0].gerado = true
              item[0].erroApi = false
              item[0].loading = false
              item[0].selecionado = false
              etiquetasGeradas += response.body
            } else {
              item[0].gerado = false
              item[0].loading = false
              item[0].erroApi = true
              item[0].erros = []
              item[0].erros.push(response.body ? response.body : response)
              etiquetasNaoGeradas += 1
              erros.push(response.body ? response.body : response)
              itemsComErro.push(item)
            }
          }).catch(error => {
            item[0].gerado = false
            item[0].erroApi = true
            item[0].loading = false
            erros.push(error.response)
          })
        }
      }
      return {
        etiquetasGeradas,
        etiquetasNaoGeradas,
        erros,
        itemsComErro,
        qtdItems,
      }
    }

    const gravaImportacao = async () => {
      state.importacao.loading = true
      const itensValidos = state.importacao.items.filter(item => item != null)
      if (itensValidos.length > 0) {
        state.importacao.items.forEach(item => {
          if (item && !item[0].gerado) {
            item[0].loading = true
          }
        })
        const { etiquetasGeradas, etiquetasNaoGeradas, erros, itemsComErro, qtdItems } = await enviaRequisicoes(state.importacao.items)
        state.importacao.loading = false
        state.importacao.items = []
        if (itemsComErro.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let x = 0; x < qtdItems; x++) {
            state.importacao.items.push(null)
          }
          // eslint-disable-next-line no-restricted-syntax
          for (const itemComErro of itemsComErro) {
            state.importacao.items.splice(itemComErro[0].numero, 1, itemComErro)
          }
        }
        let errosLista
        if (erros.length > 0) {
          errosLista = "<ul class='p-0 mt-1'>"
          erros.forEach(erro => {
            errosLista += `<li class='text-danger'>${erro}</li>`
          })
          errosLista += '</ul>'
        }
        if (etiquetasNaoGeradas > 0) {
          swal.fire({
            icon: 'warning',
            html: `${etiquetasGeradas > 0 ? etiquetasGeradas : 'Nenhuma'} etiqueta(s) gerada(s) com sucesso!`
                + ` Outra(s) ${etiquetasNaoGeradas} etiqueta(s) não será(ão) gerada(s).`
                + ` Clique em ${etiquetasGeradas > 0 ? 'OK para ser direcionado para impressão ou em ' : ''} VOLTAR para revisar.<br>`
                + `${errosLista}`,
            showConfirmButton: etiquetasGeradas > 0,
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-secondary ml-1',
            },
            confirmButtonText: 'OK',
            cancelButtonText: 'Voltar',
          }).then(result => {
            if (result.isConfirmed) {
              router.push('/envios/imprimir-etiquetas/true')
            }
          })
        } else {
          swal.fire({
            icon: 'success',
            html: `<h3>${etiquetasGeradas} etiqueta(s) gerada(s) com sucesso!`
              + ' Clique em OK para ser direcionado para impressão.</h3>',
            showConfirmButton: true,
            showCancelButton: true,
            customClass: {
              cancelButton: 'ml-1',
            },
            confirmButtonText: 'Ok, Imprimir Etq',
            cancelButtonText: 'Ok, Gerar Nova Etq',
          }).then(result => {
            if (result.isConfirmed) {
              router.push('/envios/imprimir-etiquetas/true')
            } else {
              formWizardReset()
            }
          })
        }
      } else {
        state.importacao.loading = false
        swal.fire({
          icon: 'info',
          html: '<h3>Você não selecionou nenhuma etiqueta!</h3>',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    }
    
    return {
      ...toRefs(state),
      refFormWizard,
      formWizardNextStep,
      refFormObserver, 
      getValidationState, 
      resetForm,
      gravaImportacao,
      validatorArquivo,
      validatorCampos,
      goBack,
    }
  },
  methods: {
    validaForm(validate) {
      validate().then(v => {
        if (!v.isValid) {
          Vue.$toast(
            {
              component: ToastificationContent,
              props: {
                title:
                  'Há erros no formulário. Verifique!',
                icon: 'AlertCircleIcon',
                variant: 'primary',
              },
            },
            {
              timeout: 5000,
              draggable: true,
              hideProgressBar: false,
            },
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-wizard.scss";
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~vue-form-wizard/dist/vue-form-wizard.min.css";
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}

.wizard-tab-content {
  display: flex;

  .wizard-tab-container {
    display: block;
    animation: fadeInRight 0.3s;
  }
}
</style>
